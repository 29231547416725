.Polaris-Card {
    margin-top:10px;
}

.Polaris-Layout .Polaris-Spinner {
    margin-top: 20px;
}

.main-spinner {
    margin-top:7px;
}

div.edit {
    margin-top: 5px;
}

.Polaris-EmptyState__Details .Polaris-TextContainer {
    text-align:center !important;
}

.Polaris-Card__Section .Polaris-TextContainer {
    text-align: center;
}

.Polaris-Modal-Section .Polaris-TextContainer {
    text-align: left;
}

.Polaris-DataTable__Cell--firstColumn span {
    white-space: pre-wrap;
}

.loader .Polaris-Layout{
    margin-top: 20px;
}

.Polaris-Layout__Section--oneHalf .Polaris-Card {
    min-height: 500px;
}

.Polaris-EmptyState__Image {
    width: 400px !important;
}