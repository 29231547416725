.Polaris-DisplayText {
  margin-bottom:20px;
}

.loader .Polaris-DisplayText {
  text-align: center;
}

.minor-divider {
  border-color: #ddd;
  border-style: solid;
  border-width: 1px;
  border-top: 0;
}

div.indent-section {
  padding: 0px 20px;
}

.text-center {
  text-align: center;
}

img.illustrate {
  max-width: 150px;
}

p.subdued {
  color: #637381;
}