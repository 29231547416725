.Polaris-Modal-Header__Title .Polaris-DisplayText {
  margin-bottom: 0;
}

.Polaris-Modal-Section .Polaris-TextContainer {
  margin-top: 0;
}

.loader .Polaris-DisplayText {
  text-align: center;
}